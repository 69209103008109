<template>
  <div class="viceCommentBlock">
    <div class="avatarBlock">
      <Avatar
        :providerId="viceComment.reviewerId"
        :international="
          viceComment.reviewerCountry && viceComment.reviewerCountry !== ''
            ? Number(viceComment.reviewerCountry)
            : 0
        "
        :path="viceComment.userAvatar"
        :popover="true"
      >
      </Avatar>
    </div>
    <div class="viceCommentInfo">
      <div class="viceCommentInner">
        <div :class="isVip ? 'vipName' : ''">
          <span class="viceComentAuthor">{{ viceComment.reviewer }}</span>
          <el-image
            v-if="isVip"
            style="width: 17px; height: 14px;"
            :src="require('../../../assets/images/pay/masonry.png')"
            fit="fill"
          >
          </el-image>
        </div>

        <span class="toWhom"
          ><span class="iconfont_Me icon-reply1 iconStyle"></span>
          {{ viceComment.parentReviewer }}:</span
        >
        <span
          class="viceCommentContent"
          v-html="$emoReplaceToImage(hyperlinksCheck(commentContent))"
        ></span>
      </div>
      <CommentFooter
        :comment="viceComment"
        @replyToThisPerson="replyToThisPerson"
        :type="type"
      ></CommentFooter>
    </div>
  </div>
</template>
<script>
import Avatar from "../avatarDisplay";
import CommentFooter from "./commentFooter";
export default {
  components: {
    Avatar,
    CommentFooter,
  },
  props: {
    // 副评论内容
    viceComment: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isVip: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    // 是否可以在评论里面回复
    replyInTheComponent: {
      type: Boolean,
      default: false,
    },
    // 点赞类型
    type: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    // 处理需要展示的评论内容
    commentContent() {
      return this.reconvert(this.viceComment.content);
    },
  },
  methods: {
    // 点击回复这个评论
    replyToThisPerson() {
      this.$emit("replyToThisPerson", this.viceComment);
    },
    // 将Unicode转化为评论
    reconvert(str) {
      str = str.replace(/(\\u)(\w{1,4})/gi, function($0) {
        return String.fromCharCode(
          parseInt(escape($0).replace(/(%5Cu)(\w{1,4})/g, "$2"), 16)
        );
      });
      str = str.replace(/(&#x)(\w{1,4});/gi, function($0) {
        return String.fromCharCode(
          parseInt(escape($0).replace(/(%26%23x)(\w{1,4})(%3B)/g, "$2"), 16)
        );
      });
      str = str.replace(/(&#)(\d{1,6});/gi, function($0) {
        return String.fromCharCode(
          parseInt(escape($0).replace(/(%26%23)(\d{1,6})(%3B)/g, "$2"))
        );
      });
      return str;
    },
    // 将评论转化为Unicode
    ascii(str) {
      let value = "";
      for (var i = 0; i < str.length; i++) {
        value +=
          "\&#x" + left_zero_4(parseInt(str.charCodeAt(i)).toString(16)) + ";";
      }
      return value;
    },
  },
};
</script>
<style lang="stylus" scoped>
.viceCommentBlock
  min-height 50px;
  width 100%;
  display flex;
  align-items flex-start;
  justify-content flex-start;
  margin-bottom 15px;
  &:first-child
    margin-top 10px;
  .avatarBlock
    height 30px;
    width 30px;
    flex-shrink 0;
    overflow hidden;
  .viceCommentInfo
    flex 1;
    min-width 0;
    min-height 50px;
    padding-left 13px;
    box-sizing border-box;
    .viceCommentInner
      width 100%;
      min-height 20px;
      display: flex;
      align-items: center
      .viceComentAuthor
        color #373737;
        font-size 14px;
        font-weight bold;
        margin-right 10px;
        cursor pointer;
      .toWhom
        cursor pointer;
        color #33CC66;
        font-size 14px;
        margin-right 5px;
        .iconStyle
          color #33CC66;
          font-size 14px;
      .viceCommentContent
        color #333333;
        font-size 14px;
    .vicecommentFooter
      width 100%;
      margin-top 5px;
      height 22px;
      display flex;
      align-items center;
      justify-content space-between;
      .viceCommentTime
        height 100%;
        flex 1;
        min-width 0;
        overflow hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
        color #8F8F8F;
        font-size 12px;
        display flex;
        align-items center;
        justify-content flex-start;
      .viceCommentOperatingArea
        height 100%;
        width 85px;
        flex-shrink 0;
        display flex;
        align-items center;
        justify-content flex-end;
        .buttonStyle
          cursor pointer;
          margin-right 12px;
          display flex;
          align-items center;
          justify-content flex-start;
          &:last-child
            margin-right 0;
          .likesCount
            height 22px;
            min-width 0;
            flex 1;
            display flex;
            font-size 12px;
            color #8F8F8F;
            align-items center;
            justify-content flex-start;
        .iconBox
          width 22px;
          height 22px;
          display flex;
          flex-shrink 0;
          align-items center;
          justify-content center;
          .iconStyle
            color #666666;
            font-size 22px;
</style>
