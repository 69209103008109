var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "viceCommentBlock" }, [
    _c(
      "div",
      { staticClass: "avatarBlock" },
      [
        _c("Avatar", {
          attrs: {
            providerId: _vm.viceComment.reviewerId,
            international:
              _vm.viceComment.reviewerCountry &&
              _vm.viceComment.reviewerCountry !== ""
                ? Number(_vm.viceComment.reviewerCountry)
                : 0,
            path: _vm.viceComment.userAvatar,
            popover: true
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "viceCommentInfo" },
      [
        _c("div", { staticClass: "viceCommentInner" }, [
          _c(
            "div",
            { class: _vm.isVip ? "vipName" : "" },
            [
              _c("span", { staticClass: "viceComentAuthor" }, [
                _vm._v(_vm._s(_vm.viceComment.reviewer))
              ]),
              _vm.isVip
                ? _c("el-image", {
                    staticStyle: { width: "17px", height: "14px" },
                    attrs: {
                      src: require("../../../assets/images/pay/masonry.png"),
                      fit: "fill"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c("span", { staticClass: "toWhom" }, [
            _c("span", { staticClass: "iconfont_Me icon-reply1 iconStyle" }),
            _vm._v("\n        " + _vm._s(_vm.viceComment.parentReviewer) + ":")
          ]),
          _c("span", {
            staticClass: "viceCommentContent",
            domProps: {
              innerHTML: _vm._s(
                _vm.$emoReplaceToImage(_vm.hyperlinksCheck(_vm.commentContent))
              )
            }
          })
        ]),
        _c("CommentFooter", {
          attrs: { comment: _vm.viceComment, type: _vm.type },
          on: { replyToThisPerson: _vm.replyToThisPerson }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }